import { faker, Faker } from '@faker-js/faker';
import { mockOrganization } from './mockOrganization';
import {
  OrganizationClient,
  TaxClassEnum,
  TermsOfPaymentEnum,
} from '~/api/data-contracts';

export const mockOrganizationClient = (
  f: Faker = faker,
): OrganizationClient => ({
  id: f.string.uuid(),
  serviceProvider: mockOrganization(f),
  actOfWorkContractDetails: f.lorem.sentence(),
  actOfWorkCustomerTitle: f.lorem.sentence(),
  defaultTaxClass: TaxClassEnum.Value0,
  defaultTermsOfPayment: TermsOfPaymentEnum.Value14,
});
