import { http, HttpResponse } from 'msw';
import { mockPagination } from './mockPagination';
import { faker } from '@faker-js/faker';
import { mockOrderList } from './mockOrderList';
import {
  usersApi,
  notificationsApi,
  agentsApi,
  orderLabelsApi,
  clientsApi,
  ordersApi,
  organizationsApi,
} from '../utils/api';
import { mockAgent } from './mockAgent';
import { mockClient } from './mockClient';
import { mockOrderLabel } from './mockOrderLabel';
import { mockSiteNotification } from './mockSiteNotification';
import { mockSiteNotificationCount } from './mockSiteNotificationCount';
import { mockUserSessionControlStatus } from './mockSessionControlStatus';
import { mockUserDetailed } from './mockUserDetailed';
import { mockUser } from './mockUser';
import { setupWorker } from 'msw/browser';
import { mockCountryNamesByCode } from './mockCountryNamesByCode';
import { mockOrganization } from './mockOrganization';
import { mockOrganizationDetailed } from './mockOrganizationDetailed';
import { mockOrganizationClient } from './mockOrganizationClient';
import { mockInvoiceListTotalNet } from './mockInvoiceListTotalNet';
import { mockInvoiceList } from './mockInvoiceList';
import { mockOrderDetailed } from './mockOrderDetailed';
import { mockPurchaseInvoiceDetailed } from './mockPurchaseInvoiceDetailed';
import { mockSaleInvoiceDetailed } from './mockSaleInvoiceDetailed';
import { mockCurrencyHistoricalRatesDto } from './mockCurrencyHistoricalRatesDto';
import { mockOrderShipmentUnit } from './mockOrderShipmentUnit';
import { mockOrderAgent } from './mockOrderAgent';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ApiReturnType<T extends (...args: any) => any> = Awaited<ReturnType<T>>;

// to get the same data on each request
faker.seed(100500);
faker.setDefaultRefDate(new Date('2024-08-30'));

export const handlers = [
  http.patch(`/api/v1/*`, () => {
    return HttpResponse.json({});
  }),

  http.put(`/api/v1/*`, () => {
    return HttpResponse.json({});
  }),

  http.delete(`/api/v1/*`, () => {
    return HttpResponse.json({});
  }),

  http.get(`/api/v1/users/`, () => {
    const data: ApiReturnType<typeof usersApi.usersList> =
      faker.helpers.multiple(() => mockUser(faker), { count: 25 });

    return HttpResponse.json(data);
  }),

  http.get(`/api/v1/users/me/session-control-status/`, () => {
    const data: ApiReturnType<
      typeof usersApi.usersMeSessionControlStatusRetrieve
    > = mockUserSessionControlStatus();

    return HttpResponse.json(data);
  }),

  http.get(`/api/v1/users/me/`, () => {
    const data: ApiReturnType<typeof usersApi.usersMeRetrieve> =
      mockUserDetailed(faker);

    return HttpResponse.json(data);
  }),

  http.get(`/api/v1/notifications/`, () => {
    const data: ApiReturnType<typeof notificationsApi.notificationsList> =
      faker.helpers.multiple(() => mockSiteNotification(faker), { count: 25 });

    return HttpResponse.json(data);
  }),

  http.get(`/api/v1/notifications/count`, () => {
    const data: ApiReturnType<
      typeof notificationsApi.notificationsCountRetrieve
    > = mockSiteNotificationCount(faker);

    return HttpResponse.json(data);
  }),

  http.get(`/api/v1/orders/`, () => {
    const data: ApiReturnType<typeof ordersApi.ordersList> = mockPagination(
      faker.helpers.multiple(() => mockOrderList(faker), { count: 25 }),
    );

    return HttpResponse.json(data);
  }),

  http.get(`/api/v1/agents/`, () => {
    const data: ApiReturnType<typeof agentsApi.agentsList> =
      faker.helpers.multiple(() => mockAgent(faker), { count: 25 });

    return HttpResponse.json(data);
  }),

  http.get(`/api/v1/order-labels/`, () => {
    const data: ApiReturnType<typeof orderLabelsApi.orderLabelsList> =
      faker.helpers.multiple(() => mockOrderLabel(faker), { count: 25 });

    return HttpResponse.json(data);
  }),

  http.get(`/api/v1/clients/`, () => {
    const data: ApiReturnType<typeof clientsApi.clientsList> =
      faker.helpers.multiple(() => mockClient(faker), { count: 25 });

    return HttpResponse.json(data);
  }),

  http.get(`/api/v1/definitions/countries/`, () => {
    const data: ApiReturnType<
      typeof definitionsApi.definitionsCountriesRetrieve
    > = mockCountryNamesByCode();

    return HttpResponse.json(data);
  }),

  http.get(`/api/v1/organizations/`, () => {
    const data: ApiReturnType<typeof organizationsApi.organizationsList> =
      faker.helpers.multiple(() => mockOrganization(faker), { count: 25 });

    return HttpResponse.json(data);
  }),

  http.get(`/api/v1/organizations/:organizationId/`, () => {
    const data: ApiReturnType<typeof organizationsApi.organizationsRetrieve> =
      mockOrganizationDetailed(faker);

    return HttpResponse.json(data);
  }),

  http.get(`/api/v1/organizations/:organizationId/clients/:clientId/`, () => {
    const data: ApiReturnType<
      typeof organizationsApi.organizationsClientsRetrieve
    > = mockOrganizationClient(faker);

    return HttpResponse.json(data);
  }),

  http.get(`/api/v1/invoices/`, () => {
    const data: ApiReturnType<typeof invoicesApi.invoicesList> = mockPagination(
      faker.helpers.multiple(() => mockInvoiceList(faker), { count: 25 }),
    );

    return HttpResponse.json(data);
  }),

  http.get(`/api/v1/invoices-total-net/`, () => {
    const data: ApiReturnType<
      typeof invoicesTotalNetApi.invoicesTotalNetRetrieve
    > = mockInvoiceListTotalNet(faker);

    return HttpResponse.json(data);
  }),

  http.get(`/api/v1/invoices-total-net/`, () => {
    const data: ApiReturnType<
      typeof invoicesTotalNetApi.invoicesTotalNetRetrieve
    > = mockInvoiceListTotalNet(faker);

    return HttpResponse.json(data);
  }),

  http.get(`/api/v1/invoices-total-net/`, () => {
    const data: ApiReturnType<
      typeof invoicesTotalNetApi.invoicesTotalNetRetrieve
    > = mockInvoiceListTotalNet(faker);

    return HttpResponse.json(data);
  }),

  http.get(`/api/v1/orders/:orderId/`, () => {
    const data: ApiReturnType<typeof ordersApi.ordersRetrieve> =
      mockOrderDetailed(faker);

    return HttpResponse.json(data);
  }),

  http.get(`/api/v1/orders/:orderId/invoices-for-purchase/:invoiceId/`, () => {
    const data: ApiReturnType<
      typeof ordersApi.ordersInvoicesForPurchaseRetrieve
    > = mockPurchaseInvoiceDetailed(faker);

    return HttpResponse.json(data);
  }),

  http.get(`/api/v1/orders/:orderId/invoices-for-sale/:invoiceId/`, () => {
    const data: ApiReturnType<typeof ordersApi.ordersInvoicesForSaleRetrieve> =
      mockSaleInvoiceDetailed(faker);

    return HttpResponse.json(data);
  }),

  http.get(`/api/v1/currency-rates/`, () => {
    const data: ApiReturnType<typeof currencyRatesApi.currencyRatesRetrieve> =
      mockCurrencyHistoricalRatesDto(faker);

    return HttpResponse.json(data);
  }),

  http.get(`/api/v1/orders/:orderId/shipment-units/`, () => {
    const data: ApiReturnType<typeof ordersApi.ordersShipmentUnitsList> =
      faker.helpers.multiple(() => mockOrderShipmentUnit(faker), { count: 5 });

    return HttpResponse.json(data);
  }),

  http.get(`/api/v1/orders/:orderId/agents/`, () => {
    const data: ApiReturnType<typeof ordersApi.ordersAgentsList> =
      faker.helpers.multiple(() => mockOrderAgent(faker), { count: 5 });

    return HttpResponse.json(data);
  }),
];

export const worker = setupWorker(...handlers);
