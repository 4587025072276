import {
  OrganizationDetailed,
  CountryEnum,
  CurrencyEnum,
} from '~/api/data-contracts';
import { Faker, faker } from '@faker-js/faker';

export const mockOrganizationDetailed = (
  f: Faker = faker,
): OrganizationDetailed => ({
  id: f.string.uuid(),
  name: f.company.name(),
  nameExt: f.company.name(),
  code: f.string.alphanumeric(10),
  businessId: f.string.alphanumeric(),
  countryCode: CountryEnum.AD,
  city: f.location.city(),
  streetAddress: f.location.streetAddress(),
  streetAddressExt: f.location.streetAddress(),
  postalCode: f.location.zipCode(),
  bankName: f.company.name(),
  bankAddress: f.location.streetAddress(),
  bankCity: f.location.city(),
  bankSwiftCode: f.finance.bic(),
  bankAccountNumber: f.finance.iban(),
  defaultCurrency: CurrencyEnum.USD,
  email: f.internet.email(),
  phone: f.phone.number(),
  website: f.internet.url(),
  contactPerson: f.person.firstName(),
  client: 'client-id',
  agent: f.string.uuid(),
});
