import { faker, Faker } from '@faker-js/faker';
import { InvoiceDocument } from '~/api/data-contracts';

export const mockInvoiceDocument = (f: Faker = faker): InvoiceDocument => ({
  created: f.date.recent().toISOString(),
  document: f.internet.url(),
  documentDownloadable: f.internet.url(),
  name: f.system.fileName(),
  extension: f.system.fileExt(),
});
