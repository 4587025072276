import { faker, Faker } from '@faker-js/faker';
import {
  SaleInvoiceDetailed,
  InvoiceTypeEnum,
  InvoiceStatusEnum,
  CurrencyEnum,
  SaleInvoiceBlank,
} from '~/api/data-contracts';
import { mockInvoiceDocument } from './mockInvoiceDocument';
import { mockOrderRef } from './mockOrderRef';
import { mockTermsOfPayment } from './mockTermsOfPayment';
import { mockTaxClass } from './mockTaxClass';
import { mockTransactionInSaleInvoice } from './mockTransactionInSaleInvoice';
import { mockSaleInvoiceShipmentUnitDetailed } from './mockSaleInvoiceShipmentUnitDetailed';

export const mockSaleInvoiceDetailed = (
  f: Faker = faker,
): Required<SaleInvoiceDetailed> => ({
  id: f.string.uuid(),
  order: f.string.uuid(),
  invoiceType: InvoiceTypeEnum.Sale,
  invoicePaperTitle: f.commerce.productName(),
  number: f.string.alphanumeric(10),
  status: f.helpers.arrayElement(Object.values(InvoiceStatusEnum)),
  orderRef: mockOrderRef(),
  clientRef: f.string.alphanumeric(8),
  invoicingDate: f.date.recent().toISOString().split('T')[0],
  taxClass: mockTaxClass(f),
  taxClassDisplayName: f.commerce.productAdjective(),
  termsOfPayment: mockTermsOfPayment(f),
  termsOfPaymentDisplayName: f.finance.accountName(),
  chargesTotalSumInInvoiceCurrency: f.finance.amount({
    min: 100,
    max: 10000,
    dec: 2,
  }),
  chargesOverallTotalSumInInvoiceCurrency: f.finance.amount({
    min: 100,
    max: 10000,
    dec: 2,
  }),
  invoiceCurrency: f.helpers.arrayElement(Object.values(CurrencyEnum)),
  baseCurrencyRate: f.finance.amount({ min: 0.5, max: 2, dec: 6 }),
  invoiceDocument: f.datatype.boolean() ? mockInvoiceDocument(f) : null,
  notifyPeriod: f.number.int({ min: 1, max: 30 }),
  penaltyInterest: f.number.float({ min: 0, max: 20, precision: 0.1 }),
  information: {} as SaleInvoiceBlank,
  transaction: mockTransactionInSaleInvoice(f),
  dueDate: f.date.future().toISOString().split('T')[0],
  customerBusinessId: f.string.alphanumeric(10),
  customerNumber: f.string.alphanumeric(10),
  companyBankDetails: { foo: 'bar' },
  customerName: f.company.name(),
  customerAddress: f.location.streetAddress(),
  companyBusinessId: f.string.alphanumeric(10),
  isActRequired: f.datatype.boolean(),
  shipmentUnits: faker.helpers.multiple(
    () => mockSaleInvoiceShipmentUnitDetailed(f),
    {
      count: { min: 1, max: 5 },
    },
  ),
  companyAddress: f.location.streetAddress(),
  companyName: f.company.name(),
});
