import { faker, Faker } from '@faker-js/faker';
import { OrderList, ShipmentSerieEnum } from '~/api/data-contracts';
import { mockOrderDestination } from './mockOrderDestination';
import { DirectionEnum } from '~/api/data-contracts';
import { mockOrderManagerAsUser } from './mockOrderManagerAsUser';
import { mockClient } from './mockClient';
import { mockOrderLabel } from './mockOrderLabel';
import { mockOrderShipmentUnitStats } from './mockOrderShipmentUnitStats';
import { mockTask } from './mockTask';
import { mockOrderAgent } from './mockOrderAgent';

export const mockOrderList = (f: Faker = faker): OrderList => ({
  id: f.string.uuid(),
  archived: false,
  pinned: f.datatype.boolean(),
  name: f.lorem.sentence(),
  labels: [mockOrderLabel(f)],
  clientRef: f.string.alphanumeric(8),
  client: mockClient(f),
  destinations: [
    mockOrderDestination(DirectionEnum.From),
    mockOrderDestination(DirectionEnum.Via),
    mockOrderDestination(DirectionEnum.To),
  ],
  shipmentSerie: ShipmentSerieEnum.TRA,
  managedBy: [mockOrderManagerAsUser(f)],
  shipmentUnitStats: mockOrderShipmentUnitStats(f),
  countNotCompletedTasks: f.number.int({ min: 0, max: 10 }),
  recentNotCompletedTasks: f.helpers.multiple(mockTask, {
    count: { min: 0, max: 3 },
  }),
  orderAgents: f.helpers.multiple(mockOrderAgent, {
    count: { min: 1, max: 3 },
  }),
  creationDate: toBackendDate(f.date.recent()),
});
