import {
  SiteNotification,
  SiteNotificationReactionEnum,
  NotificationTypeEnum,
} from '~/api/data-contracts';
import { toBackendDate } from '~/utils/toBackendDate';
import { Faker, faker } from '@faker-js/faker';

export const mockSiteNotification = (f: Faker = faker): SiteNotification => ({
  id: f.string.uuid(),
  created: toBackendDate(f.date.recent()),
  renderedBody: f.lorem.paragraphs(2),
  reaction: SiteNotificationReactionEnum.New,
  notificationContext: {
    notificationType: NotificationTypeEnum.Order,
    context: {
      orderId: f.string.uuid(),
      orderRef: f.string.alphanumeric(10),
      orderName: f.lorem.words(3),
    },
  },
});
