import { faker, Faker } from '@faker-js/faker';
import { OrderShipmentUnitStats } from '~/api/data-contracts';

export const mockOrderShipmentUnitStats = (
  f: Faker = faker,
): OrderShipmentUnitStats => ({
  count: f.number.int({ min: 1, max: 100 }),
  totalWeight: f.number
    .float({ min: 0, max: 1000, precision: 0.01 })
    .toFixed(2),
  totalVolume: f.number.float({ min: 0, max: 100, precision: 0.01 }).toFixed(2),
});
