// export const mockOrderShipmentUnit

import { faker, Faker } from '@faker-js/faker';
import {
  OrderShipmentUnit,
  ShipmentUnitQuantityChoice,
} from '~/api/data-contracts';
import { mockOrderShipmentReference } from './mockOrderShipmentReference';

export const mockOrderShipmentUnit = (f: Faker = faker): OrderShipmentUnit => ({
  id: f.string.uuid(),
  clientRef: f.string.alphanumeric(8),
  cargoDescription: f.commerce.productDescription(),
  quantity: f.number.int({ min: 1, max: 100 }),
  quantityMeasure: f.helpers.arrayElement(
    Object.values(ShipmentUnitQuantityChoice),
  ),
  weight: f.number.float({ min: 0.01, max: 1000, precision: 0.01 }).toFixed(2),
  volume: f.number.float({ min: 0.01, max: 100, precision: 0.01 }).toFixed(2),
  shipmentReferences: f.helpers.multiple(() => mockOrderShipmentReference(f), {
    count: { min: 1, max: 5 },
  }),
});
