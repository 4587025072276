import { OrderManagerAsUser } from '~/api/data-contracts';
import { faker, Faker } from '@faker-js/faker';

export const mockOrderManagerAsUser = (
  f: Faker = faker,
): OrderManagerAsUser => ({
  id: f.string.uuid(),
  firstName: f.person.firstName(),
  lastName: f.person.lastName(),
});
