import { faker, Faker } from '@faker-js/faker';
import { OrderAgent } from '~/api/data-contracts';
import { mockAgent } from './mockAgent';
import { toBackendDate } from '~/utils/toBackendDate';

export const mockOrderAgent = (f: Faker = faker): OrderAgent => ({
  id: f.string.uuid(),
  agent: mockAgent(f),
  bidDate: toBackendDate(f.date.recent()),
  priceConfirmed: f.datatype.boolean(),
  agentPrice: f.lorem.paragraphs(2),
  sellingPrice: f.lorem.paragraphs(2),
});
