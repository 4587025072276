import { faker, Faker } from '@faker-js/faker';
import {
  OrderShipmentReferenceData,
  BillOfLadingData,
  ContainerData,
  HouseBillOfLadingData,
  MasterBillOfLadingData,
  VehicleData,
  CmrData,
  RailwayBillOfLadingData,
} from '~/api/data-contracts';

const mockBillOfLadingData = (f: Faker): BillOfLadingData => ({
  blNumber: f.string.alphanumeric(10),
});

const mockContainerData = (f: Faker): ContainerData => ({
  containerId: f.string.alphanumeric(11),
});

const mockHouseBillOfLadingData = (f: Faker): HouseBillOfLadingData => ({
  hawb: f.string.alphanumeric(12),
  mawb: f.string.alphanumeric(13),
});

const mockMasterBillOfLadingData = (f: Faker): MasterBillOfLadingData => ({
  mawb: f.string.alphanumeric(13),
});

const mockVehicleData = (f: Faker): VehicleData => ({
  vehicleId: f.vehicle.vrm(),
  trailerId: f.vehicle.vrm(),
});

const mockCmrData = (f: Faker): CmrData => ({
  cmr: f.string.alphanumeric(14),
});

const mockRailwayBillOfLadingData = (f: Faker): RailwayBillOfLadingData => ({
  rwb: f.string.alphanumeric(15),
});

export const mockOrderShipmentReferenceData = (
  f: Faker = faker,
): OrderShipmentReferenceData => {
  const dataTypes = [
    mockBillOfLadingData,
    mockContainerData,
    mockHouseBillOfLadingData,
    mockMasterBillOfLadingData,
    mockVehicleData,
    mockCmrData,
    mockRailwayBillOfLadingData,
  ];

  return f.helpers.arrayElement(dataTypes)(f);
};
