import { faker, Faker } from '@faker-js/faker';
import {
  OrderDetailed,
  ShipmentSerieEnum,
  DirectionEnum,
} from '~/api/data-contracts';
import { mockOrderDestination } from './mockOrderDestination';
import { mockOrderManagerAsUser } from './mockOrderManagerAsUser';
import { mockClient } from './mockClient';
import { mockOrderLabel } from './mockOrderLabel';
import { mockOrderShipmentUnitStats } from './mockOrderShipmentUnitStats';

export const mockOrderDetailed = (f: Faker = faker): OrderDetailed => ({
  id: f.string.uuid(),
  archived: false,
  pinned: f.datatype.boolean(),
  name: f.lorem.sentence(),
  labels: [mockOrderLabel(f)],
  clientRef: f.string.alphanumeric(8),
  client: mockClient(f),
  consignorName: f.company.name(),
  consignorAddress: f.location.streetAddress(),
  consigneeName: f.company.name(),
  consigneeAddress: f.location.streetAddress(),
  destinations: [
    mockOrderDestination(DirectionEnum.From),
    mockOrderDestination(DirectionEnum.Via),
    mockOrderDestination(DirectionEnum.To),
  ],
  shipmentSerie: ShipmentSerieEnum.TRA,
  managedBy: [mockOrderManagerAsUser(f)],
  shipmentUnitStats: mockOrderShipmentUnitStats(f),
});
