import {
  OrderShipmentReference,
  ReferenceTypeEnum,
} from '~/api/data-contracts';
import { Faker, faker } from '@faker-js/faker';
import { mockOrderShipmentReferenceData } from './mockOrderShipmentReferenceData';

export const mockOrderShipmentReference = (
  f: Faker = faker,
): OrderShipmentReference => ({
  id: f.string.uuid(),
  referenceType: f.helpers.arrayElement(Object.values(ReferenceTypeEnum)),
  referenceData: mockOrderShipmentReferenceData(f),
});
