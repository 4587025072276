import { Faker, faker } from '@faker-js/faker';
import { InvoiceListTotalNet } from '~/api/data-contracts';

export const mockInvoiceListTotalNet = (
  f: Faker = faker,
): InvoiceListTotalNet => ({
  salesNet: String(f.number.int({ min: 10000 })),
  purchasesNet: String(f.number.int({ min: 10000 })),
  totalNet: String(f.number.int({ min: 10000 })),
  cargoTrTotalNet: String(f.number.int({ min: 10000 })),
});
