import {
  DirectionEnum,
  OrderDestination,
  CountryEnum,
} from '~/api/data-contracts';
import { Faker, faker } from '@faker-js/faker';

export const mockOrderDestination = (
  direction: DirectionEnum,
  f: Faker = faker,
): OrderDestination => ({
  direction,
  countryCode: f.helpers.arrayElement(Object.values(CountryEnum)),
});
