import { faker, Faker } from '@faker-js/faker';
import { Charge, CurrencyEnum } from '~/api/data-contracts';
import { mockOrderCharge } from './mockOrderCharge';
import { mockService } from './mockService';

export const mockCharge = (f: Faker = faker): Charge => ({
  id: f.string.uuid(),
  order: mockOrderCharge(f),
  service: mockService(f),
  transaction: f.string.uuid(),
  description: f.lorem.sentence(),
  quantity: f.number.int({ min: 1, max: 100 }),
  price: f.finance.amount({ min: 10, max: 1000, dec: 2 }),
  chargeCurrency: f.helpers.arrayElement(Object.values(CurrencyEnum)),
  baseCurrencyRate: f.finance.amount({ min: 0.5, max: 2, dec: 6 }),
  amountInInvoiceCurrency: f.finance.amount({ min: 10, max: 1000, dec: 2 }),
  vat: f.finance.amount({ min: 0, max: 25, dec: 2 }),
});
