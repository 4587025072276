import { User } from '~/api/data-contracts';
import { Faker, faker } from '@faker-js/faker';

export const mockUser = (f: Faker = faker): User => ({
  id: f.string.uuid(),
  username: f.internet.userName(),
  firstName: f.person.firstName(),
  lastName: f.person.lastName(),
  jobTitle: f.person.jobTitle(),
});
