import { CountryNamesByCode, CountryEnum } from '~/api/data-contracts';

export const mockCountryNamesByCode = (): CountryNamesByCode => ({
  countries: Object.fromEntries(
    Object.values(CountryEnum).reduce(
      (acc, code) => {
        acc.push([code, `${code}stan`]);
        return acc;
      },
      [] as [string, string][],
    ),
  ),
});
