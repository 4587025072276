import { UserDetailed } from '~/api/data-contracts';
import { mockDepartment } from './mockDepartment';
import { Faker, faker } from '@faker-js/faker';

export const mockUserDetailed = (f: Faker = faker): UserDetailed => ({
  id: f.string.uuid(),
  username: f.internet.userName(),
  firstName: f.person.firstName(),
  lastName: f.person.lastName(),
  email: f.internet.email(),
  jobTitle: f.person.jobTitle(),
  userConfigured: true,
  remoteAddr: f.internet.ip(),
  department: mockDepartment(f),
});
