import { Faker, faker } from '@faker-js/faker';
import { InvoiceList } from '~/api/data-contracts';
import { mockInvoiceType } from './mockInvoiceType';
import { mockInvoiceStatus } from './mockInvoiceStatus';
import { mockOrderInvoice } from './mockOrderInvoice';

export const mockInvoiceList = (f: Faker = faker): InvoiceList => ({
  id: f.string.uuid(),
  invoiceType: mockInvoiceType(),
  number: f.string.alphanumeric(10),
  status: mockInvoiceStatus(),
  invoicingDate: toBackendDate(f.date.recent().toISOString()),
  order: mockOrderInvoice(),
  counterparty: f.company.name(),
  transportationMethods: [],
  chargesTotalSumInBaseCurrency: f.finance.amount(),
});
