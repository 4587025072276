import { Task } from '~/api/data-contracts';
import { mockOrderRef } from './mockOrderRef';
import { faker, Faker } from '@faker-js/faker';
import dayjs from '~/utils/dayjs';

export const mockTask = (f: Faker = faker): Task => {
  return {
    description: f.lorem.sentence(),
    order: {
      name: f.commerce.productName(),
      id: f.string.uuid(),
      orderRef: mockOrderRef(f),
    },
    id: f.string.uuid(),
    dueDate: dayjs(f.date.future()).toISOString(),
  };
};
