import { faker, Faker } from '@faker-js/faker';
import {
  TransactionInPurchaseInvoice,
  AccountingTypeEnum,
} from '~/api/data-contracts';
import { mockCharge } from './mockCharge';

export const mockTransactionInPurchaseInvoice = (
  f: Faker = faker,
): TransactionInPurchaseInvoice => ({
  id: f.string.uuid(),
  accountingType: f.helpers.arrayElement([
    AccountingTypeEnum.Debit,
    AccountingTypeEnum.Credit,
  ]),
  charges: Array.from({ length: f.number.int({ min: 1, max: 5 }) }, () =>
    mockCharge(f),
  ),
  payer: f.string.uuid(),
});
